import classNames from 'classnames';
import React, { useState } from 'react';
import { HiOutlineLogout } from 'react-icons/hi';
import { DASHBOARD_SIDEBAR_BOTTOM_LINKS, DASHBOARD_SIDEBAR_LINKS } from '../../lib/constants/naviation'
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../AuthContext';

const linkClass =
    'flex items-center gap-2 font-light px-3 py-2 hover:bg-neutral-700 hover:no-underline active:bg-neutral-600 rounded-sm text-base';
const subLinkClass =
    'flex items-center gap-2 font-light px-5 py-2 text-neutral-400 hover:bg-neutral-600 hover:no-underline active:bg-neutral-600 rounded-sm text-base';

export default function Sidebar() {
    const { logout } = useAuth();

    const handleLogout = () => {
        logout();
    };

    return (
        <div className='flex flex-col bg-neutral-900 w-60 p-3 text-white'>
            <div className='flex justify-center items-center gap-2 px-1 py-3'>
                <img
                    src="/logopng.png"
                    alt="Imagem selecionada"
                    className="mb-4 rounded-full cursor-pointer"
                />
            </div>
            <div className='flex-1 py-8 flex flex-col gap-0.5'>
                {DASHBOARD_SIDEBAR_LINKS.map((item) => (
                    <SidebarLink key={item.path} item={item} />
                ))}
            </div>
            <div className='flex flex-col gap-0.5 pt-2 border-t border-neutral-700'>
                <h3 className='text-sm text-neutral-400 py-2'>Configurações</h3>
                {DASHBOARD_SIDEBAR_BOTTOM_LINKS.map((item) => (
                    <SidebarLink key={item.path} item={item} />
                ))}
                <div className={classNames(linkClass, 'cursor-pointer text-red-500')} onClick={handleLogout}>
                    <span className="text-xl">
                        <HiOutlineLogout />
                    </span>
                    Logout
                </div>
            </div>
        </div>
    );
}

function SidebarLink({ item }) {
    const { pathname } = useLocation();
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleSubLinks = () => setIsExpanded(!isExpanded);

    const hasSubLinks = item.subLinks && item.subLinks.length > 0;

    return (
        <div>
            <Link
                to={item.path}
                className={classNames(
                    pathname === item.path ? 'bg-neutral-700 text-white' : 'text-neutral-400',
                    linkClass
                )}
                onClick={hasSubLinks ? toggleSubLinks : undefined}
            >
                <span className='text-xl'>{item.icon}</span>
                {item.label}
            </Link>
            {hasSubLinks && isExpanded && (
                <div className='pl-4 flex flex-col transition-all duration-400'>
                    {item.subLinks.map((subItem) => (
                        <Link
                            key={subItem.path}
                            to={subItem.path}
                            className={classNames(
                                pathname === subItem.path ? 'bg-neutral-700 text-white' : 'text-neutral-400',
                                subLinkClass
                            )}
                        >
                            <span className='text-xl'>{subItem.icon}</span> {/* Adicione ícones aqui se necessário */}
                            {subItem.label}
                        </Link>
                    ))}
                </div>
            )}
        </div>
    );
}